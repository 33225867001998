import {Component} from 'react';

import './MainView.scss';
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import Content from '../Content/Content';


class MainView extends Component {
    render() {
        return <div className='MainView container'>
            <div className="MainView--main">
                <Header/>
                <Content/>
                <Footer/>
            </div>
        </div>
    }
}

export default MainView;
